import React, { useState, useEffect, useContext } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import "./HeroSection.css";
import MenuPopover from "./MenuPopover";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ScrollAnimation from "./ScrollAnimation";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { IoApps } from "react-icons/io5";

const HeroSection = () => {
  const [ref, inView] = useInView({ threshold: 0.5 });
  const [openMenu, setOpenMenu] = useState(null);
  const [longUrl, setLongUrl] = useState("");

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  const openPopover = Boolean(openMenu);
  const id = openPopover ? "simple-popover" : undefined;

  const [backgroundPositionY, setBackgroundPositionY] = useState(0);

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    const heroHeight = e.target.scrollHeight - e.target.clientHeight;
    const scrollPercentage = scrollTop / heroHeight;
    setBackgroundPositionY(scrollPercentage * 100);
  };

  return (
    <Box
      ref={ref}
      className="hero-container"
      sx={{
        minHeight: "100vh",
        width: "100%",
        background: `url("./herosectionbg.svg") no-repeat center center`,
        backgroundSize: "cover",
      }}

     
    >
      {" "}
      {/* Nav box*/}
      <Box
        component={motion.div}
        className="nav-box"
        initial={{ opacity: 0, y: -500 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: -500 }}
        transition={{ duration: 0.9 }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: { xs: "20px 20px", lg: "20px 40px" },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <img src="/logoIcon.svg" alt="" />
          <Typography
            sx={{
              fontWeight: 500,
              color: "#fff",
              fontSize: { xs: "20px", md: "24px" },
            }}
          >
            ShortURL
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: { xs: "20px", md: "25px" },
          }}
        >
          <Link
            to="/client/login"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button
              variant="outlined"
              sx={{
                display: { xs: "none", sm: "flex", md: "flex", lg: "flex" },
                textTransform: "none",
                border: "1px solid #AEA9BA",
                borderRadius: "156px",
                color: "#fff",
                width: { xs: "90px", md: "106px" },
                height: { xs: "40px", md: "48px" },
                fontSize: { xs: "14px", md: "16px" },
                color: "#AEA9BA",
                "&:hover": {
                  color: "#060C16",
                  border: "1px solid #AEA9BA",
                  background: "#AEA9BA",
                },
              }}
            >
              Login
            </Button>
          </Link>
          <Link
            to="/client/register"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button
              variant="outlined"
              sx={{
                display: { xs: "none", sm: "flex", md: "flex", lg: "flex" },
                textTransform: "none",
                border: "1px solid #AEA9BA",
                borderRadius: "156px",
                color: "#fff",
                width: { xs: "100px", md: "116px" },
                height: { xs: "40px", md: "48px" },
                fontSize: { xs: "14px", md: "16px" },
                color: "#AEA9BA",
                "&:hover": {
                  color: "#060C16",
                  border: "1px solid #AEA9BA",
                  background: "#AEA9BA",
                },
              }}
            >
              Register
            </Button>
          </Link>
          <Box>
            <IconButton
              onClick={handleOpenMenu}
              sx={{
                color: "#fff",
                "&:hover": {
                  color: "#fff",
                  background: "#AEA9BA",
                },
              }}
            >
              <IoApps size={20} />
            </IconButton>
            <MenuPopover
              openMenu={openMenu}
              handleCloseMenu={handleCloseMenu}
              openPopover={openPopover}
              id={id}
            />
          </Box>
        </Box>
      </Box>
      {/* Add short url */}
      <motion.div
        style={{
          width: { xs: "90%" },
          marginTop: "110px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
        initial={{ opacity: 0, y: -500 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: -500 }}
        transition={{ duration: 1 }}
      >
        <Typography
          className="hero-heading"
          sx={{
            fontSize: { xs: "24px", md: "36px" },
            letterSpacing: { xs: "16px", md: "25px" },
            lineHeight: { xs: "40px", md: "80px" },
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          Shrink URLs,Expand Reach
          {/* Shorten Links <span style={{ fontWeight: 300 }}>or URL</span> */}
        </Typography>
        <Box
          className="hero-url-box"
          sx={{
            mt: "45px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            borderRadius: "51px",
            background: "rgba(17, 22, 43, 0.70)",
            backdropFilter: "blur(10.600000381469727px)",
            width: { xs: "90%", sm: "90%", lg: "903px" },
            padding: { xs: "30px", md: "50px" },
            gap: "20px",
          }}
        >
          <Box sx={{ width: { xs: "100%", md: "65%" }, position: "relative" }}>
            <input
              type="text"
              name=""
              value={longUrl}
              onChange={(e) => setLongUrl(e.target.value)}
              placeholder="Paste Your Long URL Here"
              className="hero-input"
            />
            <img src="./star.svg" alt="star" className="star-icon" />
          </Box>

          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: "10px", md: "16px" },
            }}
          > */}
          <Link
            to={longUrl && "/client/login"}
            style={{
              textDecoration: "none",
              color: "inherit",
              cursor: longUrl ? "pointer" : "default",
            }}
          >
            <Button
              variant="contained"
              disabled={!longUrl}
              sx={{
                color: "#060C16",
                fontWeight: 500,
                borderRadius: "100px",
                background: "#fff",
                fontSize: { xs: "14px", md: "16px" },
                padding: { xs: "0px 25px", md: "0px 35px" },
                height: { xs: "50px", md: "62px" },
                "&:hover": {
                  color: "#060C16",
                  background: "#AEA9BA",
                },
                "&.Mui-disabled": {
                  backgroundColor: "#AEA9BA",
                  color: "#060C16",
                },
              }}
            >
              SHorten URL Now
            </Button>
          </Link>
          {/* <IconButton
              sx={{
                color: "#000000",
                background: "#fff",
                padding: { xs: "10px", md: "16px" },
                "&:hover": {
                  color: "#060C16",
                  background: "#AEA9BA",
                },
              }}
            >
              <ChevronRightIcon sx={{ width: "26px", height: "26px" }} />
            </IconButton> */}
          {/* </Box> */}
        </Box>
      </motion.div>
      {/* Auto scroll animation box */}
      <Box>
        <ScrollAnimation />
      </Box>
    </Box>
  );
};

export default HeroSection;
