import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import LinkIcon from "@mui/icons-material/Link";
import LogoutIcon from "@mui/icons-material/Logout";
import CloseIcon from "@mui/icons-material/Close";
import LightModeIcon from "@mui/icons-material/LightMode";
import { FaLinkSlash } from "react-icons/fa6";
import { MdOutlineLightMode, MdOutlineDarkMode } from "react-icons/md";

const AppBar = ({ handleCancel, theme, setTheme }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        p: "16px 0px",
        width: "90%",
        m: "auto",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <img
          src="/logoIcon.svg"
          alt=""
          style={{ width: "26px", height: "26px" }}
        />
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: { xs: "16px", sm: "18px", lg: "20px" },
            color: theme === "light" ? "#060C16" : "#fff",
          }}
        >
          ShortURL
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
     
   
        <IconButton
          sx={{
            background:
              theme === "light" ? "#F0EDF9" : "rgba(255, 255, 255, 0.08)",
            color: theme === "light" ? "#6231F7" : "#fff",
            "&:hover": {
              background: "rgba(255, 255, 255, 0.11)",
            },
          }}
          onClick={toggleTheme}
        >
          {theme === "light" ? (
            <MdOutlineDarkMode size={18} />
          ) : (
            <MdOutlineLightMode size={18} />
          )}
          {/* <LightModeIcon sx={{ width: "18px", height: "18px" }} /> */}
        </IconButton>
    
        <Button
          variant="contained"
          sx={{
            borderRadius: "534px",
            minWidth: "90px",
            background: theme === "light" ? "#F0EDF9" : "#2A1F3C",
            color: theme === "light" ? "#6231F7" : "#fff",
            fontWeight: theme === "light" ? 550 : 550,
            fontSize: "16px",
            textTransform: "none",
            boxShadow: "none",
            "&:hover": {
              background: theme === "light" ? "#F0EDF9" : "#2A1F3C",
              boxShadow: "none",
            },
          }}
        >
          Links
        </Button>
        <IconButton
          sx={{
            background:
              theme === "light" ? "#F0EDF9" : "rgba(255, 255, 255, 0.08)",
            border: "1px solid transparent",
            color: theme === "light" ? "#060C16" : "#fff",
            transition: "border 0.3s ease",

            "&:hover": {
              border:
                theme === "light" ? "1px solid #8B71D8" : "1px solid #fff",
            },
          }}
        >
          <LogoutIcon sx={{ width: "18px", height: "18px" }} />
        </IconButton>
        <Tooltip title="Close Modal" placement="bottom" arrow>
          <IconButton
            onClick={handleClick}
            sx={{
              background:
                theme === "light" ? "#F0EDF9" : "rgba(255, 255, 255, 0.08)",
              border: "1px solid transparent",
              color: theme === "light" ? "#060C16" : "#fff",
              transition: "border 0.3s ease",

              "&:hover": {
                border:
                  theme === "light" ? "1px solid #8B71D8" : "1px solid #fff",
              },
            }}
          >
            <CloseIcon sx={{ width: "18px", height: "18px" }} />
          </IconButton>
        </Tooltip>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{
            boxShadow: 2,
            ".MuiPaper-root": {
              borderRadius: "126px",
              boxShadow: 2,
            },
          }}
        >
          <MenuItem
            sx={{
              color: "#060D17",
              fontWeight: 550,
              borderRadius: "126px",
            }}
            onClick={handleCancel}
          >
            Close Demo
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default AppBar;
